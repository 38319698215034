import React from "react"
import "./ContentHome.jsx.scss"
import BannerReservation from "../BannerReservation/BannerReservation"
import PrincipalInfo from "../PrincipalInfo/PrincipalInfo"
import SectionRooms from "../SectionRooms/sectionRooms"
import ParallaxReviews from "../ParalaxReviews/ParallaxReviews"
import { graphql, useStaticQuery } from "gatsby"
import Reservation from "../../molecules/Reservation/Reservation"


const ContentHome = () => {


  const { allContentfulContenidoHome } = useStaticQuery(
    graphql`
        query Home_Query {
            allContentfulContenidoHome {
                nodes {
                    metaTitulo
                    metaDescription {
                        metaDescription
                    }
                    imagenBanner{
                        fluid(maxWidth: 1980) {
                            aspectRatio
                            base64
                            sizes
                            src
                            srcSet
                        }
                        description
                    }

                    descripcionPrincipal {
                        raw
                    }
                    imagenes {
                        fluid {
                            aspectRatio
                            base64
                            sizes
                            src
                            srcSet
                        }
                        description
                    }
                    habitaciones {
                        nombreHabitacion
                        descripcionHabitacion {
                            raw
                        }
                        caracteristicas {
                            descripcion
                            icono
                        }
                        imagenHabitacion {
                            fluid {
                                aspectRatio
                                base64
                                sizes
                                src
                                srcSet
                            }
                            description
                            file {
                                url
                            }
                        }
                        precioHabitacion
                    }
                    seccionResenias {
                        titulo
                        descripcion {
                            descripcion
                        }
                        imagenFondo {
                            fluid {
                                aspectRatio
                                base64
                                sizes
                                src
                                srcSet
                            }
                            description
                            file {
                                url
                            }
                        }
                        reseanias {
                            nombre
                            fecha
                            fotoUsuario {
                                fluid {
                                    aspectRatio
                                    base64
                                    sizes
                                    src
                                    srcSet
                                }
                                description
                            }
                            comentario {
                                comentario
                            }
                        }
                    }
                }
            }
        }

    `
  )
  const contentInfo = allContentfulContenidoHome.nodes[0]

  return (
    <>
      <BannerReservation img={contentInfo.imagenBanner} />
      <Reservation />
      <PrincipalInfo info={contentInfo.descripcionPrincipal} gallery={contentInfo.imagenes} />
      <SectionRooms rooms={contentInfo.habitaciones} />
      <ParallaxReviews content={contentInfo.seccionResenias} />
    </>

  )
}

export default ContentHome
