import React, { useState } from "react"
import "./SelectNumber.scss"

const SelectNumber = ({ label, min = 0, setValue, value }) => {


  const handleNumber = action => {

    if (action === "+") {
      setValue(value + 1)
    } else {
      if (value !== min) {
        setValue(value - 1)
      }
    }
  }

  return (
    <div className="select-number">
      <span className="select-number__label">{label}</span>
      <div className="select-number__buttons">
        <button
          onClick={(e) => {
            e.preventDefault()
            handleNumber("-")
          }}
        >-
        </button>
        <div><span>{value}</span></div>
        <button
          onClick={(e) => {
            e.preventDefault()
            handleNumber("+")
          }}
        >+
        </button>

      </div>

    </div>
  )
}

export default SelectNumber
