import React from "react"
import "./GridGalleryHome.scss"


import Img from "gatsby-image"

const GridGalleryHome = ({ gallery }) => {


  return (
    <div className="grid-gallery">

      {gallery.map((item, index) => {
        return (
          <div className="grid-gallery__img" key={`gallery-${index}`}>
            <Img
              className="img-fluid"
              loading="lazy"
              itemProp="image"
              fluid={item.fluid}
              alt={item.description}
              draggable={false}
            />
            <span className="grid-gallery__img-description">{item.description}</span>
          </div>
        )
      })}


    </div>
  )
}

export default GridGalleryHome
