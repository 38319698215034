import React, { useState } from "react"
import DateRange from "../../atoms/DateRange/DateRange"
import SelectNumber from "../../atoms/SelectNumber/SelectNumber"
import Loading from "../../atoms/loading/loading"
import Modal from "../Modal/Modal"

import "./Reservation.scss"

const Reservation = () => {
  const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

  //Date reservation
  const [arrival, setArrival] = useState(null)
  const [departure, setDeparture] = useState(null)

  //Persons
  const [adults, setAdults] = useState(1)
  const [children, setChildren] = useState(0)

  const [names, setNames] = useState("")
  const [telphone, setTelphone] = useState("")

  const [mail, setMail] = useState("")


  const [dateError, setDateError] = useState(false)
  const [namesError, setNamesError] = useState(false)
  const [telphoneError, setTelphoneError] = useState(false)
  const [mailError, setMailError] = useState(false)

  const [modalSendOk, setModalSendOk] = useState(false)
  const [modalSendError, setModalSendError] = useState(false)

  const [sending, setSending] = useState(false)


  const handleSubmit = e => {
    e.preventDefault()
    if (!haveErrors()) {
      sendEmail()
    }
  }


  const haveErrors = () => {


    if (names === "" || names === " ") {
      setNamesError("Este campo es requerido")
    } else {
      setNamesError(false)
    }

    if (telphone === "" || telphone === " ") {
      setTelphoneError("Este campo es requerido")
    } else {
      setTelphoneError(false)
    }

    if (mail === "") {
      setMailError("Este campo es requerido")
    } else if (!(emailRegex.test(mail))) {
      setMailError("Correo inválido")
    } else {
      setMailError(false)
    }


    if (arrival === "" || arrival === " " || arrival === null || departure === "" || departure === " " || departure === null) {
      setDateError("Este campo es requerido")
    } else if (arrival >= departure) {
      setDateError("Fecha invalida")
    } else {
      setDateError(false)
    }

    return (names === "" || names === " ") ||
      (telphone === "" || telphone === " ") ||
      (mail === "") || (!(emailRegex.test(mail))) ||
      (arrival === "" || arrival === " " || arrival === null || departure === "" || departure === " " || departure === null)
  }

  const sendEmail = () => {
    setSending(true)

    let params = {
      user_id: process.env.EMAILJS_USER_ID,
      service_id: process.env.EMAILJS_SERVICE_ID,
      template_id: process.env.EMAILJS_TEMPLATE_RESERVATION,
      template_params: {
        "arrival": formatDate(arrival),
        "departure": formatDate(departure),
        "adults": adults,
        "children": children,
        "names": names,
        "telphone": telphone,
        "email": mail,
      }
    }

    let headers = {
      "Content-type": "application/json"
    }

    let options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(params)
    }

    fetch("https://api.emailjs.com/api/v1.0/email/send", options)
      .then((res) => {
        if (res.ok) {
          setModalSendOk(true)
        } else {
          console.log("error")
          setModalSendError(true)
        }
      })
      .catch((error) => {
        console.log("Oops... " + error)
        setModalSendError(true)

      })
      .finally(() => {
        setSending(false)
      })

  }

  const formatDate = (date) => {
    return date.toLocaleString("default", { month: "long" }) + " " + date.getDate() + ", " + date.getFullYear()
  }

  return (

    <>

      {modalSendOk && (
        <Modal
          modalForm={true}
          setShowModal={setModalSendOk}
          actions={() => {
            setNames("")
            setTelphone("")
          }}
        >
          <h3 className="modal__title">Muchas Gracias Por Reservar Con Nosotros</h3>
          <p className="modal__paragraph">¡Muchas gracias por reservar con nosotros! te responderemos tan pronto como
            nos sea posible.</p>
          <p className="modal__paragraph">Los datos de tu solicitud son:</p>
          <ul className="modal__list">
            <li><span> Fecha de Llegada:</span> {formatDate(arrival)}</li>
            <li><span>Fecha de Salida:</span> {formatDate(departure)}</li>
            <li><span>Adultos:</span> {adults}</li>
            <li><span>Niños:</span> {children}</li>
            <li><span>Nombres:</span> {names}</li>
            <li><span>Telefono:</span> {telphone}</li>
          </ul>
        </Modal>
      )}

      {modalSendError && (
        <Modal modalForm={true} setShowModal={setModalSendError}>
          <h3 className="modal__title error">¡Error Al Solicitar La Resserva!</h3>
          <p className="modal__paragraph error">Hemos tenido problemas al enviar el formulario, por favor inténtalo
            nuevamente.</p>
        </Modal>
      )}


      <div className="reservation-form">

        <div className="reservation-form__wrapper">
          <div className="reservation-form__calendar">
            <div className="reservation-form__calendar-container">
              <form className="reservation-form__calendar-date" onSubmit={handleSubmit}>
                <DateRange setArrival={setArrival} setDeparture={setDeparture} dateError={dateError} />
                <div className="reservation-form__calendar-date-persons">
                  <SelectNumber label="Adultos" min={1} setValue={setAdults} value={adults} />
                  <SelectNumber label="Niños" setValue={setChildren} value={children} />
                </div>
                <div className="reservation-form__calendar-date-input">
                  <label>Nombres</label>
                  <input type="text"
                         placeholder="Nombres y Apelldios"
                         value={names}
                         onChange={e => setNames(
                           e.target.value
                             .replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ ]/g, "")
                             .replace(/ {2}/g, " ")
                             .slice(0, 80)
                         )}
                  />
                  {namesError && <p className="error-input">{namesError}</p>}

                </div>
                <div className="reservation-form__calendar-date-input">
                  <label>Teléfono</label>
                  <input
                    type="tel"
                    placeholder="Teléfono"
                    value={telphone}
                    onChange={e => setTelphone(
                      e.target.value
                        .replace(/\D/, "")
                        .slice(0, 10)
                    )}
                  />
                  {telphoneError && <p className="error-input">{telphoneError}</p>}

                </div>
                <div className="reservation-form__calendar-date-input">
                  <label>Correo</label>
                  <input type="email"
                         name="mail"
                         value={mail}
                         onChange={e => setMail(
                           e.target.value
                             .replace(/ /g, "")
                             .slice(0, 80)
                         )}
                  />
                  {mailError && <p className="error-input">{mailError}</p>}

                </div>

                {sending ? (
                  <div className="reservation-sending">
                    <Loading />
                  </div>
                ) : (
                  <button className="reservation-submit">Solicitar Reserva</button>
                )}
              </form>


            </div>
          </div>
        </div>

      </div>
    </>

  )
}

export default Reservation
