import React from "react"
import "./PrincipalInfo.scss"
import GridGalleryHome from "../../molecules/GridGalleryHome/GridGalleryHome"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"

const PrincipalInfo = ({ info, gallery }) => {

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <h1 className="principal-info__title">{children}</h1>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="principal-info__paragraph">{children}</p>
    }
  }

  return (
    <>
      <section className="principal-info">
        <div className="principal-info__wrapper">
          <div className="principal-info__txt">
            {documentToReactComponents(JSON.parse(info.raw), options)}
          </div>
          <GridGalleryHome gallery={gallery}/>
        </div>
      </section>
    </>
  )
}

export default PrincipalInfo
