import React, { useEffect, useState } from "react"
import "./DateRange.scss"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import { registerLocale, setDefaultLocale } from "react-datepicker"
import es from "date-fns/locale/es"

registerLocale("es", es)


const DateRange = ({ setArrival, setDeparture,dateError }) => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)

  useEffect(() => {
    setArrival(startDate)
    setDeparture(endDate)
  }, [startDate, endDate])

  return (
    <div className="date-reservation">
      <div>
        <span>Fecha de Llegada</span>
        <DatePicker
          selected={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="MMMM d, yyyy"
          minDate={new Date()}
          locale="es"
        />
      </div>
      <div>
        <span>Fecha de Salida</span>
        <DatePicker
          selected={endDate}
          onChange={date => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          dateFormat="MMMM d, yyyy"
          locale="es"
          placeholderText="Fecha de Salida"
        />
      </div>
      { dateError && <p className="reservation-error-input">{dateError}</p> }
    </div>
  )
}

export default DateRange
