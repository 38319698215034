import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import FeatureRoom from "../../atoms/FeatureRoom/FeatureRoom"
import { useInView } from "react-intersection-observer"
import { BLOCKS } from "@contentful/rich-text-types"

const RoomHome = ({ direction, room, index }) => {

  const [ref, inView] = useInView()
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (inView) {
      // controls.start("visible");
      setVisible(true)
    }
  }, [inView])

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="section-rooms__room-description-paragraph">{children}</p>
    }
  }


  const formatMoney = (money) => {
    return money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  }


  return (
    <div className={`section-rooms__room ${direction}`}>
      <div className={`section-rooms__room__wrapper ${direction}`}>
        <div className="section-rooms__room-img">
          <Img
            className="img-fluid"
            loading="lazy"
            itemProp="image"
            fluid={room.imagenHabitacion.fluid}
            alt={room.imagenHabitacion.description}
            draggable={false}
          />
        </div>
        <div className={`section-rooms__room-description ${direction}`}>
          <h2 className="section-rooms__room-description-title">{room.nombreHabitacion}</h2>
          {documentToReactComponents(JSON.parse(room.descripcionHabitacion.raw), options)}
          <ul className="section-rooms__room-features">
            {room.caracteristicas.map((feature, indexFeature) => {
              return (
                <li key={`feature-${indexFeature}`}>
                  <FeatureRoom key={indexFeature} feature={feature} />
                </li>
              )
            })}
          </ul>

          <div className={`section-rooms__room__price ${direction} ${visible ? "visible" : ""}`} ref={ref}>
            <p><sup>$</sup>{formatMoney(room.precioHabitacion)}</p>
            <span>Por noche</span>
          </div>


        </div>
      </div>
    </div>
  )
}

export default RoomHome
