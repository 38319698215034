import React, { useState } from "react"
import "./BannerReservation.scss"
import Img from "gatsby-image"


const BannerReservation = ({ img }) => {

  return (
      <section className="banner-reservation">
        <div className="banner-reservation__img">
          <Img
            className="img-fluid"
            loading="lazy"
            itemProp="image"
            fluid={img.fluid}
            alt={img.description}
            draggable={false}
          />
        </div>
      </section>
  )
}

export default BannerReservation
