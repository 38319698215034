import React from "react"
import "./sectionRooms.scss"
import RoomHome from "../../molecules/RoomHome/RoomHome"

const SectionRooms = ({ rooms }) => {


  return (
    <section className="section-rooms">
      <div className="section-rooms__wrapper">
        {rooms.map((room, index) => {
          let direction = (index % 2 === 0) ? "right" : "left"
          return (
            <RoomHome direction={direction} room={room} key={index}/>
          )
        })}

      </div>
    </section>
  )
}

export default SectionRooms
