import React from "react"
import Slider from "react-slick"
import "./ParallaxReviews.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Img from "gatsby-image"


const ParallaxReviews = ({ content }) => {


  const settings = {
    dots: true,
    speed: 1500,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false
  }

  const formatDate = (date) => {
    const event = new Date(date);
    const options = { year: "numeric", month: "long", day: "numeric" }
    return event.toLocaleDateString(undefined, options)
  }

  return (
    <section className="parallax-reviews">

      <div className="parallax-reviews__parallax">
        <Img
          className="img-fluid"
          loading="lazy"
          itemProp="image"
          fluid={content.imagenFondo.fluid}
          alt={content.imagenFondo.description}
          draggable={false}
        />
      </div>
      <div className="parallax-reviews__wrapper">
        <div className="parallax-reviews__txt">
          <h2>{content.titulo}</h2>
          <p>{content.descripcion.descripcion}  </p>
        </div>
        <div className="parallax-reviews__testimonials">
          <Slider {...settings}>
            {content.reseanias.map((testimony, index) => {
              return (
                <div key={index} className="parallax-reviews__testimonials-testimony">
                  <div className="parallax-reviews__testimonials-author">
                    <div className="parallax-reviews__testimonials-author-img">
                      {/*<img src={testimony.image} />*/}
                      <Img
                        className="img-fluid"
                        loading="lazy"
                        itemProp="image"
                        fluid={testimony.fotoUsuario.fluid}
                        alt={testimony.fotoUsuario.description}
                        draggable={false}
                      />
                    </div>
                    <div className="parallax-reviews__testimonials-author-info">
                      <p>{testimony.nombre}</p>
                      <span>{formatDate(testimony.fecha)}</span>
                    </div>
                  </div>
                  <blockquote className="parallax-reviews__testimonials-txt">
                    <p>
                      {testimony.comentario.comentario}
                    </p>
                  </blockquote>
                </div>
              )

            })}

          </Slider>
        </div>
      </div>
    </section>
  )
}

export default ParallaxReviews
