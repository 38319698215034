import * as React from "react"

import Layout from "../components/organisms/Layout/Layout"
import SEO from "../components/seo"
import "../styles/index.scss"
import ContentHome from "../components/organisms/ContentHome/ContentHome"
import { graphql } from "gatsby"

const IndexPage = (props) => {
  const { allContentfulContenidoHome } = props.data
  return (
    <Layout>
      <SEO
        title={allContentfulContenidoHome.nodes[0].metaTitulo}
        description={allContentfulContenidoHome.nodes[0].metaDescription.metaDescription}
      />
      <ContentHome />
    </Layout>
  )
}

export const query = graphql`
    query Meta_Query_Home{
        allContentfulContenidoHome {
            nodes {
                metaTitulo
                metaDescription {
                    metaDescription
                }
            }
        }
    }
`

export default IndexPage
